export const ServicesData = [
    {
        id: 1,
        title: 'Консультация онлайн',
        duration: 50,
        coast: 4000,
        format: 'онлайн',
        place: 'WatsApp / FaceTime / GoogleMeet',
        description: 'С чем вы можете обратиться ко мне с переживаниями, эмоциями (гнев, страх, агрессия и др.), тревогами, межличностными отношениями, профессиональной и личностной самореализацией.'
    },
    {
        id: 2,
        title: 'Консультация в Москве',
        duration: 50,
        coast: 5000,
        format: 'очно',
        place: 'Оговаривается заранее',
        description: 'С чем вы можете обратиться ко мне с переживаниями, эмоциями (гнев, страх, агрессия и др.), тревогами, межличностными отношениями, профессиональной и личностной самореализацией.'
    },
    {
        id: 3,
        title: 'Пакет консультаций "4x50"',
        duration: 50,
        coast: 14000,
        format: 'онлайн',
        place: 'WatsApp / FaceTime / GoogleMeet',
        description: 'С чем вы можете обратиться ко мне с переживаниями, эмоциями (гнев, страх, агрессия и др.), тревогами, межличностными отношениями, профессиональной и личностной самореализацией.'
    },
  ];